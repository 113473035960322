import styled from "styled-components"

type Props = {
  fill?: string
  width?: "fit" | "fill"
}

const Button = styled.button<Props>`
  background: ${props => props.fill || "#ffb954"};
  border: none;
  border-radius: 4px;
  padding: 16px 32px;
  cursor: pointer;
  color: #003764;
  font-size: 20px;
  font-weight: 600;
`

export default Button
