import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Frame from "./frame"
import useMeasure from "react-use-measure"

type Props = {
  children: JSX.Element
}

const Hero: React.FC<Props> = ({ children }) => {
  const [ref, { width }] = useMeasure()

  console.log(width)

  const breakpoint = 1200

  return (
    <>
      <div
        ref={ref}
        style={{
          display: "grid",
          // height: width * 0.34 + 140 + "px",
        }}
      >
        <StaticImage
          style={{
            gridArea: "1/1",

            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          // height={600}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          src={"../images/hero.jpg"}
          formats={["auto", "webp", "avif"]}
          quality={100}
        />

        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",

            // This centers the other elements inside the hero component
            // placeItems: "center",
            display: "grid",
          }}
        >
          {/* Any content here will be centered in the component */}
          {width >= breakpoint && (
            <Frame align="center">
              <Frame maxWidth={1200} style={{ flex: 1 }}>
                <Frame maxWidth={620}>{children}</Frame>
              </Frame>
            </Frame>
          )}
        </div>
      </div>
      {/* {tight && children} */}
      {width < breakpoint && children}
    </>
  )
}

export default Hero
