import React from "react"
import Frame from "./frame"

type Props = {
  children: JSX.Element
}

const Banner: React.FC<Props> = ({ children }) => {
  return (
    <Frame fill="#003764" align="center">
      <Frame maxWidth={1200} style={{ flex: 1 }}>
        {children}
      </Frame>
    </Frame>
  )
}

export default Banner
