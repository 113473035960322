import React from "react"
import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { SizeMe } from "react-sizeme"

import Hero from "../components/hero"
import Banner from "../components/banner"
import Text from "../components/text"
import Frame from "../components/frame"
import Button from "../components/button"
import Grid from "../components/grid"
import Layout from "../components/layout"

const QuizLink = ({ children }: { children: React.ReactNode }) => (
  <Link to="/to-quiz">{children}</Link>
)

const IndexPage = () => {
  const contentWidth = 1200
  return (
    <Layout>
      <>
        <Hero>
          <Frame direction="vertical">
            <Frame direction="vertical" p={2} gap={1} fill="white">
              <Text
                family="din-2014"
                size="44px"
                color="#006FB7"
                lineHeight={1.1}
              >
                We are fighting the world's deadliest cancer
              </Text>
              <Text family="Roboto" size="20px" weight={300}>
                Too often, lung cancer is diagnosed too late to treat.
              </Text>
            </Frame>
            <Frame
              align="center"
              px={2}
              py={1}
              fill="#EAF1FD"
              spacing="space-between"
              wrap={true}
              gap={1}
            >
              <Text family="Roboto" size="20px" color="#006FB7">
                Early detection is key
              </Text>
              <div style={{ flex: 1 }}></div>
              <QuizLink>
                <Button>
                  <Text
                    family="din-2014"
                    size="24px"
                    weight={600}
                    color="#003764"
                  >
                    Take the quiz now!
                  </Text>
                </Button>
              </QuizLink>
            </Frame>
          </Frame>
        </Hero>

        <Banner>
          <Frame direction="vertical" gap={1} px={2} py={4}>
            <Text color="white" size="32px" family="din-2014">
              Our quiz takes less than a minute and it might just save your
              life.
            </Text>
            <Text
              color="white"
              size="16px"
              lineHeight={1.45}
              family="Roboto"
              weight={300}
            >
              You’ll receive your results <b>immediately</b>—which you can then
              share and discuss with your GP.
            </Text>
          </Frame>
        </Banner>
        <Frame style={{ justifyContent: "center" }}>
          {/* <SizeMe>
            {({ size }) => ( */}
          <Frame
            maxWidth={contentWidth}
            wrap="reverse"
            gap={3}
            p={2}
            style={{ alignItems: "center" }}
          >
            <Frame
              direction="vertical"
              gap={1}
              // style={{ flex: 1 }}
              // style={{ flex: "0 1 600px" }}
              maxWidth={550}
            >
              <Text size="40px" color="#006FB7" weight={300} family="din-2014">
                Find out more about lung cancer.
              </Text>
              <Text weight={300} lineHeight={1.45} family="Roboto">
                <p>
                  Lung cancer is the deadliest cancer in Australia and up to
                  10,000 Australians die from it every year. Approximately
                  170,000 Australians will be diagnosed with lung cancer in the
                  next decade.
                </p>
                <p>
                  <b>Common symptoms</b> include:
                </p>
                <ul>
                  <li>persistent coughing for more than three weeks</li>
                  <li>coughing blood</li>
                  <li>a hoarse or changing voice</li>
                  <li>difficulty breathing or wheezing</li>
                  <li>chest, rib or shoulder pain</li>
                  <li>unexplained weight loss</li>
                  <li>fatigue and weakness.</li>
                </ul>
                <p>
                  <b>Our quiz</b> is based on international research that is
                  widely used to assess lung cancer. It goes by the unwieldy
                  name of PLCOm2012.
                </p>
                <p>
                  Essentially, it’s a risk assessment tool that calculates
                  factors such as age, family history and smoking status to
                  determine your risk of getting lung cancer.
                </p>
              </Text>
              <QuizLink>
                <Button>
                  <Text
                    family="din-2014"
                    size="24px"
                    weight={600}
                    color="#003764"
                  >
                    Take the quiz now!
                  </Text>
                </Button>
              </QuizLink>
            </Frame>
            {/* <div
              style={{
                flex: 1,
                flexBasis: "400px",
                alignContent: "center",
                justifyContent: "center",
              }}
            > */}
            {/* Something */}
            {/* <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
                paddingTop: "56.25%",
              }}
            >
              <iframe
                // width="560"
                // height="315"
                src="https://www.youtube.com/embed/lG8wZhsIr1c"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div> */}

            {/* </div> */}
            {/* <StaticImage
              src="../images/youtube.png"
              alt=""
              style={{ flex: 1, flexBasis: "400px" }}
              objectFit="contain"
            /> */}
          </Frame>
          {/* )}
          </SizeMe> */}
        </Frame>

        <Frame align="center">
          <Frame maxWidth={contentWidth} gap={3} p={2}>
            <StaticImage
              src="../images/family.png"
              alt=""
              style={{ flex: 1, flexBasis: "400px" }}
              objectFit="contain"
            />
            <Frame
              direction="vertical"
              gap={1}
              // style={{ flex: "1 0 auto" }}
              maxWidth={550}
            >
              <Text size="40px" color="#006FB7" weight={300} family="din-2014">
                Taken the Quiz?
              </Text>
              <Text size="20px" color="#006FB7" weight={400} family="din-2014">
                What happens when your risk factors are high
              </Text>
              <Text family="Roboto" weight={300} lineHeight={1.45}>
                <p>
                  If the quiz tells you your risk factors are high or if you
                  have symptoms, please follow these steps.
                </p>
                <p>
                  <b>
                    Step 1: Book an appointment with your GP as soon as
                    possible.
                  </b>
                  <br />
                  It doesn’t mean you have cancer—but your GP can refer you to
                  Lungscreen for a scan and a detailed report. Early
                  intervention saves lives.
                </p>
                <p>
                  <b>
                    Step 2: Take the referral letter and the request form to
                    your GP.
                  </b>
                  <br />
                  We’ll send you an email that attaches a referral letter for
                  your GP, as well as a request form for a low-dose CT scan.
                  Your GP will perform a thorough medical examination and will
                  fill out and sign one of our request forms.
                </p>
                <p>
                  <b>Step 3: Return the form to us.</b>
                  <br />
                  We’ll arrange your scan and report. We’ll send you and your GP
                  our detailed findings and recommendations. We’ll then continue
                  to support your health by sending you reminders so you don’t
                  miss your appointments and follow-up scans. Contact us if you
                  have any questions.
                </p>
              </Text>
            </Frame>
          </Frame>
        </Frame>
        <Frame fill="#EAF1FD" align="center" px={2} py={4}>
          <Frame maxWidth={900} direction="vertical" align="center" gap={2}>
            <Text size="40px" color="#003764" weight={300} family="din-2014">
              Welcome to Lungscreen Foundation
            </Text>
            <Text weight={300} lineHeight={1.45} family="Roboto">
              Improving lung health is our ultimate goal. We’re a 100%
              not-for-profit organisation committed to providing lung cancer
              screening access for every Australian. Every dollar of profit from
              our screening goes directly into programs and measures that will
              help to save even more lives, including:
            </Text>
            <Frame py={2} maxWidth={700} style={{ flex: 1, width: "100%" }}>
              <Grid>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/bulb.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      Lung cancer screening improvements
                    </Text>
                  </Frame>
                </Frame>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/lungs.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      Lung cancer research
                    </Text>
                  </Frame>
                </Frame>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/nurse.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      Funding for lung cancer nurses
                    </Text>
                  </Frame>
                </Frame>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/no-smoking.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      Quit smoking programs
                    </Text>
                  </Frame>
                </Frame>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/book.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      Lung cancer education
                    </Text>
                  </Frame>
                </Frame>
                <Frame align="center" direction="vertical" gap={0.5}>
                  <StaticImage
                    src="../images/icons/lung-gear.svg"
                    alt="Lungscreen Foundation Logo"
                    height={78}
                  />
                  <Frame maxWidth={180} align="center">
                    <Text color="#003764" align="center" family="Roboto">
                      New technologies
                    </Text>
                  </Frame>
                </Frame>
              </Grid>
            </Frame>
            <Text weight={300} lineHeight={1.45} family="Roboto">
              Right now, lung screening costs money. So we’re working alongside
              Cancer Australia and the Lung Foundation to urge the Federal
              Government to introduce free lung screening for Australians—just
              as other countries have.
            </Text>
            <Text weight={500} lineHeight={1.45} family="Roboto">
              Our free lung screening quiz will help save lives.
            </Text>
            <QuizLink>
              <Button>
                <Text
                  family="din-2014"
                  size="24px"
                  weight={600}
                  color="#003764"
                >
                  Take the quiz now!
                </Text>
              </Button>
            </QuizLink>
          </Frame>
        </Frame>

        <SizeMe noPlaceholder>
          {({ size }) => {
            const isStacked = size.width && size.width < contentWidth
            return (
              <Frame direction={isStacked ? "vertical" : "horizontal"}>
                <Frame
                  fill="white"
                  align={isStacked ? "left" : "right"}
                  p={2}
                  style={{ flexBasis: "50%" }}
                >
                  <Frame direction="vertical" gap={2} maxWidth={600 - 32}>
                    <Text
                      size="40px"
                      weight={300}
                      color="#003764"
                      family="din-2014"
                    >
                      How much does lung cancer screening cost?
                    </Text>
                    <Frame direction="vertical" gap={1}>
                      <Text weight={300} lineHeight={1.45} family="Roboto">
                        <p>
                          The National Lung Cancer Screening Program (NLCSP) in
                          Australia is set to begin in July 2025. As part of
                          this initiative, eligible individuals will have access
                          to free lung cancer screening using low-dose computed
                          tomography (LDCT) scans. The program targets high-risk
                          populations, specifically those aged between 50 and 70
                          years who have a smoking history of at least 30
                          pack-years. Eligible individuals within this group
                          will be invited to participate in the screening
                          program to detect lung cancer at an earlier and more
                          treatable stage​​​​​​.
                        </p>
                        <p>
                          Until the national program is fully implemented, the
                          cost of lung cancer screening for individuals not
                          covered by specific programs or research initiatives
                          may still be out-of-pocket expenses. The cost of this
                          screening service may vary. You can obtain this
                          service through the Lungscreen Foundation, where the
                          cost is $300.
                        </p>
                        <p>
                          For further details about NLCSP, you can visit the
                          <a href="https://www.health.gov.au/our-work/nlcsp">
                            Australian Government Department of Health's NLCSP
                            page
                          </a>
                          .
                        </p>
                      </Text>
                    </Frame>
                  </Frame>
                </Frame>
                <Frame
                  fill="#FFB954"
                  align={isStacked ? "left" : "left"}
                  p={2}
                  style={{ flexBasis: "50%" }}
                >
                  <Frame direction="vertical" gap={2} maxWidth={600 - 32}>
                    <Text
                      size="40px"
                      weight={300}
                      color="#003764"
                      family="din-2014"
                    >
                      More information is just a click away
                    </Text>
                    <Frame direction="vertical" gap={1}>
                      <Text weight={300} lineHeight={1.45} family="Roboto">
                        <ul>
                          <li>Lung cancer in Australia and its harm</li>
                          <li>Benefits of lung cancer screening</li>
                          <li>
                            Lung cancer screening facts and clinical evidence
                          </li>
                          <li>Low dose CT</li>
                        </ul>
                      </Text>
                      <QuizLink>
                        <Link to="/articles/find-out-more-about-lung-cancer-screening">
                          <Button fill="white">
                            <Text
                              family="din-2014"
                              size="24px"
                              weight={600}
                              color="#003764"
                            >
                              Find out more
                            </Text>
                          </Button>
                        </Link>
                      </QuizLink>
                    </Frame>
                  </Frame>
                </Frame>
              </Frame>
            )
          }}
        </SizeMe>
      </>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
